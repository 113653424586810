<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Data Protection Policy</h1>
          <h3>GDPR Compliant</h3>
          <ul>
            <li>
              <a href="#introduction">Introduction</a>
            </li>
            <li>
              <a href="#why-needed">Why This Policy is Needed</a>
            </li>
            <li>
              <a href="#policy-scope">Policy Scope</a>
            </li>
            <li>
              <a href="#data-protection-risks">Data Protection Risks</a>
            </li>
            <li>
              <a href="#responsibilities">Responsibilities</a>
            </li>
            <li>
              <a href="#staff-guidelines">General Staff Guidelines</a>
            </li>
            <li>
              <a href="#data-storage">Data Storage</a>
            </li>
            <li>
              <a href="#data-use">Data Use</a>
            </li>
            <li>
              <a href="#data-accuracy">Data Accuracy</a>
            </li>
            <li>
              <a href="#subject-access-requests">Subject Access Requests</a>
            </li>
            <li>
              <a href="#information-removal-requests"
                >Information Removal Requests</a
              >
            </li>
            <li>
              <a href="#disclosure">Disclosing Data for Other Reasons</a>
            </li>
            <li>
              <a href="#providing-information">Providing Information</a>
            </li>
            <li>
              <a href="#cookies">Cookies</a>
            </li>
          </ul>

          <p><b>Approved by Senior Management on:</b> 23rd May 2018</p>
          <p><b>Policy became operation on:</b> 25th May 2018</p>
          <p><b>Next review date:</b> 29th April 2019</p>

          <h4 id="introduction">Introduction</h4>

          <p>
            Amchara Limited (UK) and Amchara Ltd (Malta) need to gather
            and use certain information about individuals. These can include
            customers, suppliers, business contacts, employees and other people
            the company has a relationship with or may need to contact.
          </p>

          <p>
            This policy describes how this personal data must be collected,
            handled and stored to meet the company’s data protection
            standards – and to comply with the law.
          </p>

          <h4 id="why-needed">Why This Policy is Needed</h4>

          <p>
            The GDPR (General Data Protection Regulation) 2018 describes how
            organisations – including Amchara Limited and Amchara Ltd –
            must collect, handle and store personal information.
          </p>

          <p>
            These rules apply regardless of whether data is stored
            electronically, on paper or on other materials.
          </p>
          <p>
            To comply with the law, personal information must be collected and
            used fairly, stored safely and not disclosed unlawfully.
          </p>

          <p>The GDPR is underpinned by six core principles:</p>

          <p>
            <b>1. Lawfulness, fairness and transparency</b>
          </p>
          <p>
            Personal data shall be processed lawfully, fairly and in a
            transparent manner in relation to the data subject.
            <br />Lawful: Processing must meet the tests described in GDPR
            [article 5, clause 1(a)]. <br />Fair: What is processed must match
            up with how it has been described. <br />Transparency: Tell the
            subject what data processing will be done.
          </p>

          <p>
            <b>2. Purpose limitations</b>
          </p>
          <p>
            Personal data can only be obtained for “specified, explicit and
            legitimate purposes” [article 5, clause 1(b)]. Data can only be used
            for a specific processing purpose that the subject has been made
            aware of and no other, without further consent.
          </p>

          <p>
            <b>3. Data minimisation</b>
          </p>
          <p>
            Data collected on a subject should be “adequate, relevant and
            limited to what is necessary in relation to the purposes for which
            they are processed” [article 5, clause 1(c)]. In other words, no
            more than the minimum amount of data should be kept for specific
            processing.
          </p>

          <p>
            <b>4. Accuracy</b>
          </p>
          <p>
            Data must be “accurate and where necessary kept up to date” [article
            5, clause 1(d)]. Baselining ensures good protection and protection
            against identity theft. Data holders should build rectification
            processes into data management / archiving activities for subject
            data.
          </p>

          <p>
            <b>5. Storage limitations</b>
          </p>
          <p>
            Regulator expects personal data is “kept in a form which permits
            identification of data subjects for no longer than necessary”
            [article 5, clause 1(e)]. In summary, data no longer required should
            be removed.
          </p>

          <p>
            <b>6. Integrity and confidentiality</b>
          </p>
          <p>
            Personal data shall be processed in a manner that ensures
            appropriate security of the personal data, including protection
            against unauthorised or unlawful processing and against accidental
            loss, destruction or damage, using appropriate technical or
            organisational measures.
          </p>

          <p>
            <b>PEOPLE, RISKS AND RESPONSIBILITIES</b>
          </p>

          <h4 id="policy-scope">Policy Scope</h4>

          <p>This policy applies to:</p>
          <ul>
            <li>The head office of Amchara Limited and Amchara Ltd</li>
            <li>All branches of Amchara Limited and Amchara Ltd</li>
            <li>All staff at Amchara Limited and Amchara Ltd</li>
            <li>
              All therapists, contractors, suppliers and other people working on
              behalf of Amchara Limited and Amchara Ltd
            </li>
          </ul>

          <p>
            It applies to all data that the companies hold relating to
            identifiable individuals, even if that information technically falls
            outside of the GDPR 2018. This can include:
          </p>
          <ul>
            <li>Names of individuals</li>
            <li>Postal addresses</li>
            <li>Email addresses</li>
            <li>Telephone numbers</li>
            <li>…plus any other information relating to individuals</li>
          </ul>

          <h4 id="data-protection-risks">Data Protection Risks</h4>

          <p>
            This policy helps to protect Amchara Limited and Amchara Ltd
            from some very real data security risks, including:
          </p>
          <ul>
            <li>
              Breaches of confidentiality. For instance, information being given
              out inappropriately.
            </li>
            <li>
              Failing to offer choice. For instance, all individuals should be
              free to choose how the company uses data relating to them.
            </li>
            <li>
              Reputational damage. For instance, the company could suffer if
              hackers successfully gained access to sensitive data.
            </li>
          </ul>

          <h4 id="responsibilities">Responsibilities</h4>

          <p>
            Everyone who works for or with Amchara Limited and Amchara Ltd
            has some responsibility for ensuring data is collected, stored and
            handled appropriately.
          </p>
          <p>
            Each team that handles personal data must ensure that it is handled
            and processed in line with this policy and data protection
            principles.
          </p>

          <h4 id="staff-guidelines">General Staff Guidelines</h4>

          <ul>
            <li>
              The only people able to access data covered by this policy should
              be those who need it for their work
            </li>
            <li>Data should not be shared informally.</li>
            <li>
              Amchara Limited and Amchara Ltd will provide training to all
              employees to help them understand their responsibilities when handling
              data.
            </li>
            <li>
              Employees should keep all data secure, by taking sensible
              precautions and following the guidelines below.
            </li>
            <li>
              Personal data should not be disclosed to unauthorised people,
              either within the company or externally.
            </li>
            <li>
              Data should be regularly reviewed and updated if it is found to be
              out of date. If no longer required, it should be deleted and
              disposed of.
            </li>
          </ul>

          <h4 id="data-storage">Data Storage</h4>

          <p>
            These rules describe how and where data should be safely stored.
            Questions about storing data safely can be directed to the Chief
            Technology Officer or data controller.
          </p>

          <p>
            When data is stored on paper, it should be kept in a secure place
            where unauthorised people cannot see it.
          </p>

          <p>
            These guidelines also apply to data that is usually stored
            electronically but has been printed out for some reason:
          </p>
          <ul>
            <li>
              When not required, the paper or files should be kept in a locked
              drawer or filing cabinet.
            </li>
            <li>
              Employees & contractors should make sure paper and printouts are
              not left where unauthorised people could see them.
            </li>
            <li>
              Data printouts should be shredded and disposed of securely when no
              longer required.
            </li>
            <li>
              By law all client medical notes & records must be kept on file for
              at least 8 years, financial records for 6 years. At the end of
              each government financial year (1st April to 31st March), these
              are archived and locked away for secure storage.
            </li>
            <li>
              When data is stored electronically, it must be protected from
              unauthorised access, accidental deletion and malicious hacking
              attempts:
            </li>
            <li>
              Data should be protected by strong passwords that are changed
              regularly and never shared between employees.
            </li>
            <li>
              If data is stored on removable media (like a CD or DVD, USB memory
              stick or memory card), these should be kept locked away securely
              when not being used.
            </li>
            <li>
              Data should only be stored on designated drives and servers, and
              should only be uploaded to an approved cloud computing services.
            </li>
            <li>
              Servers containing personal data should be sited in a secure
              location, away from general office space.
            </li>
            <li>
              Data should be backed up frequently. Those backups should be
              tested regularly, in line with the company’s standard backup
              procedures.
            </li>
            <li>
              Data should never be saved directly to mobile devices like tablets
              or smart phones.
            </li>
            <li>
              Mobile laptops that have data stored directly to them, should have
              a secure password for them and always be locked whenever left
              unattended. If leaving them for any length of time when not at
              work, they should be locked away in a secure place.
            </li>
            <li>
              All servers and computers containing data should be protected by
              approved security software and a firewall.
            </li>
          </ul>

          <h4 id="data-use">Data Use</h4>
          <p>
            Personal data is of no value to Amchara Limited / Amchara Ltd
            unless the business can make use of it. However, it is when personal
            data is accessed and used that it can be at the greatest risk of loss,
            corruption or theft:
          </p>
          <ul>
            <li>
              When working with personal data, employees should ensure the
              screens of their computers are always locked when left unattended.
            </li>
            <li>
              Personal data should not be shared informally. In particular, card
              details & bank account information should never be sent by email,
              and any other personal data eg health questionnaires should have
              minimal contact with email, and only when necessary if it cannot
              be sent in any other way, as this form of communication is not
              secure.
            </li>
            <li>
              Data must be encrypted before being transferred electronically.
            </li>
            <li>
              Personal & sensitive data is stored on servers in the UK and
              should never be transferred outside of the European Economic Area.
            </li>
            <li>
              Employees should not save copies of personal data to their own
              computers. Always access and update the central copy of any data.
            </li>
          </ul>

          <h4 id="data-accuracy">Data Accuracy</h4>
          <p>
            The law requires Amchara Limited / Amchara Ltd to take reasonable
            steps to ensure data is kept accurate and up to date.
          </p>

          <p>
            The more important it is that the personal data is accurate, the
            greater the effort Amchara Limited / Amchara Ltd should put into
            ensuring its accuracy.
          </p>

          <p>
            It is the responsibility of all employees who work with data to take
            reasonable steps to ensure it is kept as accurate and up to date as
            possible.
          </p>
          <ul>
            <li>
              Data will be held in as few places as necessary. Staff should not
              create any unnecessary additional data sets.
            </li>
            <li>
              Staff should take every opportunity to ensure data is updated. For
              instance, by confirming a customer’s details when they call.
            </li>
            <li>
              Amchara Limited / Amchara Ltd will make it easy for data subjects
              to update the information Amchara Limited / Amchara Ltd holds
              about them. For instance, over the phone, by email or via the company
              website.
            </li>
            <li>
              Data should be updated as inaccuracies are discovered. For
              instance, if a client can no longer be reached on their stored
              telephone number, it should be removed from the database.
            </li>
            <li>
              It is the marketing manager’s responsibility to ensure marketing
              databases are checked against industry suppression files every six
              months.
            </li>
          </ul>

          <h4 id="subject-access-requests">Subject Access Requests</h4>

          <p>
            All individuals who are the subject of personal data held by
            Amchara Limited / Amchara Ltd are entitled to:
          </p>
          <ul>
            <li>Ask what information the company holds about them and why.</li>
            <li>Ask how to gain access to it.</li>
            <li>Be informed how to keep it up to date.</li>
            <li>
              Be informed how the company is meeting its data protection
              obligations.
            </li>
          </ul>
          <p>
            If an individual contacts the company requesting this information,
            this is called a subject access request.
          </p>

          <p>
            Subject access requests from individuals should be made by email,
            addressed to the data controller at change@amchara.com. The data
            controller can supply a standard request form, although individuals
            do not have to use this.
          </p>

          <p>
            Individuals will not be charged for subject access requests. The
            data controller will aim to provide the relevant data within 14
            days.
          </p>

          <p>
            The data controller will always verify the identity of anyone making
            a subject access request before handing over any information.
          </p>

          <h4 id="information-removal-requests">
            Information Removal Requests
          </h4>
          <p>
            All individuals who are the subject of personal data held by
            Amchara Limited / Amchara Ltd are entitled to request that the
            information that is held about them is removed from the system.
          </p>

          <p>
            Information removal requests from individuals should be made by
            email, addressed to the data controller at change@amchara.com. The
            data controller can supply a standard request form, although
            individuals do not have to use this.
          </p>

          <p>
            The data controller will aim to remove the relevant data within
            48hrs.
          </p>

          <h4 id="disclosure">Disclosing Data for Other Reasons</h4>

          <p>
            In certain circumstances, the GDPR allows personal data to be
            disclosed to law enforcement agencies without the consent of the
            data subject.
          </p>

          <p>
            Under these circumstances, Amchara Limited / Amchara Ltd will
            disclose requested data. However, the data controller will ensure
            the request is legitimate, seeking assistance from the Senior
            Management team and from the company’s legal advisors where necessary.
          </p>

          <p>
            Private health records, may be shared with appropriate therapists,
            nurses or doctors, if and when Amchara deem it either necessary or
            appropriate for the client’s benefit and for best practice.
          </p>

          <h4 id="providing-information">Providing Information</h4>
          <p>
            Amchara Limited / Amchara Ltd aims to ensure that individuals
            are aware that their data is being processed, and that they understand:
          </p>
          <ul>
            <li>How the data is being used.</li>
            <li>How to exercise their rights.</li>
          </ul>
          <p>
            To these ends, the company has a privacy statement, setting out how
            data relating to individuals is used by the company.
          </p>

          <p>
            The company privacy statement is available on request, and is also
            available on the company’s website: www.amchara.com/privacy.
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="col">
        <div class="row">
          <h2 id="cookies">Your Privacy</h2>
        </div>

        <div class="row">
          <div class="col-md-12 -fixed">
            Your privacy is important to us. To better protect your privacy we
            provide this notice explaining our online information practices and
            the choices you can make about the way your information is collected
            and used. To make this notice easy to find, we make it available on
            our homepage and at every point where personally identifiable
            information may be requested.
          </div>
        </div>
        &nbsp;
        <div class="row"></div>
        &nbsp;
        <div class="row">
          <h2>Collection of Personal Information</h2>
        </div>

        <div class="row">
          <div class="col-md-12 -fixed">
            When visiting www.amchara.com the IP address used to access the site
            will be logged along with the dates and times of access. This
            information is purely used to analyze trends, administer the site,
            track users movement and gather broad demographic information for
            internal use. Most importantly, any recorded IP addresses are not
            linked to personally identifiable information.
          </div>
        </div>
        &nbsp;
        <div class="row"></div>
        &nbsp;
        <div class="row">
          <h2>Collection &amp; Storage of Your Financial Details</h2>
        </div>

        <div class="row">
          <div class="col-md-12 -fixed">
            We do not store any of your financial details (credit or debit card
            numbers) payments are processed by PayPal Virtual Terminal.
          </div>
        </div>
        &nbsp;
        <div class="row"></div>
        &nbsp;
        <div class="row">
          <h2>Links to third party Websites</h2>
        </div>

        <div class="row">
          <div class="col-md-12 -fixed">
            We have included links on this site for your use and reference. We
            are not responsible for the privacy policies on these websites. You
            should be aware that the privacy policies of these sites may differ
            from our own.
          </div>
        </div>
        &nbsp;
        <div class="row"></div>
        &nbsp;
        <div class="row">
          <h2 class>IP addresses and cookies</h2>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p>
              We may collect information about your computer, including your IP
              address, operating system and browser type, for system
              administration, and to report aggregate information to our
              advertisers. These are statistical data about our users browsing
              actions and patterns, and do not identify any individual.
            </p>

            <p>
              For the same reason, we may obtain information about your general
              internet usage by using a cookie file. Cookies are small text
              files that are placed on your computer by websites that you visit.
              They therefore contain information that is transferred to your
              device, such as computer hard disk or mobile phone.
            </p>

            <p>
              Cookies help us to improve our site and to deliver a better and
              more personalised service. They enable us:
            </p>
            <ul>
              <li>To estimate our audience size and usage pattern</li>
              <li>
                To store information about your preferences, and so allow us to
                customise our site according to your individual interests
              </li>
              <li>To increase the speed of your searches</li>
              <li>To recognise you when you return to our site.</li>
            </ul>

            <p>
              You may refuse to accept cookies by activating the setting on your
              browser which allows you to refuse the setting of cookies.
              However, if you select this setting you may be unable to access
              certain parts of our site. Unless you have adjusted your browser
              setting so that it will refuse cookies, our system will issue
              cookies when you log on to our site.
            </p>

            <p>
              Our advertisers may also use cookies, over which we have no
              control.
            </p>

            <p>
              Our sites produce some cookies which are essential to keep the
              site working and provide the services you need. These include:
            </p>
            <ul>
              <li>
                Cookies that enable you to stay logged in for an entire visit to
                the site
              </li>
              <li>
                Cookies which enable you to add things to a shopping basket and
                keep them there until you decide to check out.
              </li>
            </ul>

            <h5>Cookies which help us improve the site</h5>

            <p>
              Some cookies help us to see how people are using our sites. These
              are known as analytics cookies or tracking cookies. They let us
              know what areas of website are popular and how people are using
              the site. This information is anonymous we do not collect anything
              that would identify a user. We combine this data with the rest of
              the visitor data and examine it as a whole to build up a picture
              of how the site is performing. These cookies work only on our site
              and are not shared.
            </p>

            <p>
              We also place tiny tags (also known as tracking pixels or web
              beacons) on some of the emails we send out. These do not collect
              any personal information either, but they let us know how many
              people are opening and reading our emails so that we can choose
              more popular content.
            </p>

            <p>
              We use cookies to test and improve the quality of our website
              service. For instance, when we are testing new website content we
              ask some of our visitors to answer a brief survey. To make sure
              that we do not ask the same people repeatedly and interfere with
              their browsing too much, we set a cookie which remembers if they
              have been asked to participate and what their answers were.
            </p>

            <p>
              On occasion, we also run multiple versions of a page. Some of our
              visitors will be shown one version of a page and we then use our
              analytics to see if this has helped people access more information
              or experience a better user journey with us. To prevent confusion
              we set a cookie to make sure that our visitors always see the same
              version of a page instead of all the different ones.
            </p>

            <h5>Cookies which help us get value for money from advertising</h5>

            <p>
              We occasionally use other cookies to track how our advertisements
              perform. As with all our analytics cookies, all the information we
              collect is anonymous. These cookies are used solely to let us know
              which site a visitor clicked on for our advertisement, that they
              have come through to our site and completed whatever action we
              wished to see promoted such downloading a leaflet or ordering an
              information pack.
            </p>

            <h5>Can I decline to accept cookies?</h5>
            <p>
              You are not obliged to accept a cookie that we (or any other web
              server) send to you. Most browsers accept cookies automatically
              but you can modify the settings in your browser to turn off this
              feature. However, cookies are integral to some areas of the
              website and to certain online services offered by Diabetes Centre,
              so if you choose not to accept cookies from us, you may not be
              able to utilise some areas of the website and we may not be able
              to provide certain online services to you.
            </p>

            <h5>Changes to this Privacy Statement</h5>
            <p>
              The contents of this statement may be altered at any time, at our
              discretion.
            </p>

            <p>
              If you have any questions regarding our privacy policy then you
              may
              <a class="normal-green" href="/contact/">contact us.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
};
</script>

<style>
</style>